/** @format */
import './src/styles/global.css';

import React from 'react';

import { PrivacyJurisdiction } from './src/components/layout/footer/privacyJurisdiction/privacyJurisdictionContext';
import { wrapWithLayout } from './src/components/layout/layout';

import type { GatsbyBrowser } from 'gatsby';
import type { ReactElement } from 'react';

async function enableMocking() {
    const { worker } = await import('./src/mocks/browser');
    return worker.start();
}

if (process.env.NODE_ENV === 'development' && process.env.ENABLE_MOCKING === 'true') {
    enableMocking();
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }): ReactElement => {
    return wrapWithLayout(element, props);
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }): ReactElement => {
    return <PrivacyJurisdiction>{element}</PrivacyJurisdiction>;
};
